import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import mustache from 'mustache';

import { Wrapper, Input, Button, SearchIcon, ButtonIcon, Container } from './Search.styled';

export type HeightType = 'default' | 'tall';

export interface Props {
    placeholder: string;
    search: {
        href: string;
        ariaLabel: string;
    };
    label: string;
    showIcon?: boolean;
    name?: string;
    className?: string;
    autoComplete?: string;
    handleValueChange?: (value: string) => void;
    heightType?: HeightType;
    themeColor?: {
        color?: React.CSSProperties['color'];
        backgroundColor?: React.CSSProperties['backgroundColor'];
    };
}

const Search: React.FC<Props> = props => {
    const {
        placeholder,
        search,
        className,
        label,
        showIcon,
        name = 'search',
        autoComplete,
        handleValueChange,
        heightType = 'default',
        themeColor
    } = props;
    const [value, setValue] = useState('');
    const searchButtonRef = useRef() as MutableRefObject<HTMLAnchorElement>;
    const searchHref = mustache.render(search.href, { search: value });

    //nextjs router removed as returned undefined when used in the fragment developed for legacy versions of the site. Replaced with simulated click.
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            searchButtonRef.current.click();
        }
    };

    const handleChange = (event: React.SyntheticEvent): void =>
        setValue((event.target as HTMLInputElement).value);

    useEffect(() => {
        if (typeof handleValueChange === 'function') {
            handleValueChange(value);
        }
    }, [value]);

    return (
        <Container
            style={
                {
                    '--color': themeColor?.color,
                    '--bg-color': themeColor?.backgroundColor
                } as React.CSSProperties
            }
        >
            <Wrapper className={className}>
                <Input
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    name={name}
                    aria-label={label}
                    autoComplete={autoComplete}
                    type="search"
                    $heightType={heightType}
                />
                <SearchIcon />
            </Wrapper>
            <Button
                aria-label={search.ariaLabel}
                href={searchHref}
                className="button e-search-input-submit"
                ref={searchButtonRef}
                $heightType={heightType}
            >
                {showIcon ? <ButtonIcon /> : `Search`}
            </Button>
        </Container>
    );
};

export default Search;
