import React from 'react';

import type { Props as LinkProps } from '../Link';

import { Container, IconContainer, Icon } from './Arrow.styled';

export interface Props extends LinkProps {
    iconOutline?: boolean;
    iconBorder?: boolean;
    iconColor?: string;
    isHovered?: boolean; // facilitates hover when covering another element with pseudo
}

const Arrow: React.FC<Props> = props => {
    const { label, children, variant, iconOutline, iconBorder, iconColor, isHovered, ...rest } =
        props;

    return (
        <Container
            {...rest}
            variant={variant}
            $variant={variant}
            $outline={iconOutline}
            $iconColor={iconColor}
            $iconBorder={iconBorder}
        >
            {label ? label : children}
            <IconContainer
                $outline={iconOutline}
                $iconBorder={iconBorder}
                $isHovered={isHovered && isHovered}
            >
                <Icon />
            </IconContainer>
        </Container>
    );
};

export default Arrow;
