import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { Chevron } from '@solent-university/solent-icons';
import {
    Tag as BaseTag,
    Link
} from 'Modules/SiteNavigation/subcomponents/Subnavigation/Subnavigation.styled';

export const Container = styled(animated.div)`
    ${({ theme }) => css`
        position: absolute;
        left: 0;
        width: 100%;
        border-radius: 0 0.75rem 0.75rem 0;
        display: flex;
        flex-direction: column;
        color: ${theme.modules.siteNavigation.primaryNavigation.color};
        overflow: hidden;
        height: 100%;
    `}
`;

export const BackButton = styled.button`
    ${({ theme }) => css`
        background-color: ${theme.modules.siteNavigation.backButton.background};
        border: none;
        padding: 1rem 1rem 1rem calc(1rem + 2.25rem);
        color: inherit;
        text-align: left;
        font-weight: 600;
        position: relative;
        margin: 0;
        -webkit-apparence: none;

        &:hover {
            text-decoration: underline;
        }
    `}
`;

export const BackIcon = styled(Chevron)`
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    left: 1.75rem;
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    transition: left 200ms ease;

    ${BackButton}:hover & {
        left: 2rem;
    }
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        position: relative;
        height: 100%;
        background: ${theme.modules.siteNavigation.primaryNavigation.background};

        @media ${theme.breakpoints.medium.media} {
            display: flex;
            overflow: auto;
        }
    `}
`;

export const View = styled(animated.div)`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        max-height: 100%;
        background: inherit;
        padding: 1.5rem 1rem;
        width: 100%;
        overflow-y: auto;

        @media ${theme.breakpoints.medium.media} {
            position: relative;
            width: 50%;
        }
    `}
`;

export const PrimaryList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
`;

export const Item = styled.li`
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`;

export const Icon = styled.svg<{ $iconColor: string }>`
    ${({ $iconColor }) => css`
        display: inline-block;
        width: 1.5rem;
        height: 1.25rem;
        margin-right: 0.75rem;
        position: relative;
        transform: translateY(25%);
        color: ${$iconColor};
        stroke-width: 2;
    `}
`;

export const SecondaryNavigationButton = styled.button`
    ${({ theme }) => css`
        border-radius: 0.75rem;
        color: inherit;
        padding: 0.75rem;
        width: 100%;
        text-align: left;
        margin-top: 1.5rem;
        font-size: 1rem;
        position: relative;
        background: ${theme.modules.siteNavigation.secondaryNavigation.button.background};
        border: 0.0625rem solid #575757;

        @media ${theme.breakpoints.medium.media} {
            display: none;
        }
    `}
`;

export const SecondaryNavigationButtonIcon = styled(Chevron)`
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
    transition: right 200ms ease;

    ${SecondaryNavigationButton}:hover & {
        right: 0.25rem;
    }
`;

export const SecondaryView = styled(View)`
    background: ${({ theme }) => theme.modules.siteNavigation.background};
`;

export const SecondaryHeading = styled.p`
    ${({ theme }) => css`
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5;
        margin: 0 0 1.5rem;
        color: ${theme.modules.siteNavigation.secondaryNavigation.color};
    `}
`;

export const SecondaryList = styled.ul`
    ${({ theme }) => css`
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        color: ${theme.modules.siteNavigation.secondaryNavigation.color};

        ${Link} {
            margin-right: 4.25rem;
            text-decoration: underline;
        }
    `}
`;

export const Tag = styled(BaseTag)`
    font-size: 0.875rem;
`;
