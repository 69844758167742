import React, { useRef, MutableRefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Cross } from '@solent-university/solent-icons';

import {
    ModalStyled,
    BodyFixed,
    Inner,
    CloseButton,
    StyledIcon,
    Heading,
    Description
} from './Modal.styled';

export interface Props {
    children?: React.ReactChild;
    closeFnc: () => void;
    id: string;
    customWidth?: string;
    heading?: string;
    headingType?: 'h2' | 'h3';
    headingColour?: string;
    description?: string;
    isVisible: boolean;
}

const Modal: React.FC<Props> = props => {
    const {
        children,
        closeFnc,
        id,
        customWidth,
        isVisible,
        heading,
        headingType = 'h2',
        headingColour,
        description,
        ...rest
    } = props;

    const [modalTrigger, setModalTrigger] = useState<HTMLElement | null>(null);
    const modalRef = useRef() as MutableRefObject<HTMLDivElement>;

    const handleWrapperClick = event => {
        if (event.target === event.currentTarget) {
            closeFnc();
        }
    };

    const handleKeyPress = e => {
        switch (e.key) {
            case 'Escape':
                closeFnc();
                break;
            case 'Tab': {
                const focusableModalElements = modalRef.current.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
                ) as NodeListOf<HTMLElement>;

                const firstElement = focusableModalElements[0];
                const lastElement = focusableModalElements[focusableModalElements.length - 1];

                if (!e.shiftKey && document.activeElement === lastElement) {
                    e.preventDefault();
                    firstElement.focus();
                }

                if (e.shiftKey && document.activeElement === firstElement) {
                    e.preventDefault();
                    lastElement.focus();
                }
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        if (isVisible) {
            setModalTrigger(document.activeElement as HTMLElement);
            modalRef.current.focus();
        } else {
            modalTrigger?.focus();
        }
    }, [isVisible]);

    return !isVisible
        ? null
        : createPortal(
              <ModalStyled
                  onClick={handleWrapperClick}
                  role="dialog"
                  id={`modal_${id}`}
                  aria-modal={true}
                  aria-labelledby={`modal_heading_${id}`}
                  aria-hidden={!isVisible}
                  onKeyDown={handleKeyPress}
                  ref={modalRef}
                  tabIndex={-1}
                  {...rest}
              >
                  <BodyFixed />
                  <Inner data-view={true} $customwidth={customWidth}>
                      <CloseButton
                          onClick={closeFnc}
                          aria-label="Close modal"
                          $headingColour={headingColour}
                      >
                          <StyledIcon icon={Cross} />
                      </CloseButton>
                      {heading && (
                          <Heading
                              id={`modal_heading_${id}`}
                              as={headingType}
                              $headingColour={headingColour}
                          >
                              {heading}
                          </Heading>
                      )}
                      {description && <Description>{description}</Description>}
                      {children}
                  </Inner>
              </ModalStyled>,
              document.getElementById('portal') as HTMLDivElement
          );
};

export default Modal;
