import styled, { css } from 'styled-components';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/reso/wave-promo-socials.svg';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        background-color: var(--bg-promo-socials, ${theme.colors.accents[8].hex});
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 0.5rem;
        padding: 2.5rem 1.25rem;
        overflow: hidden;
        display: grid;
        place-items: center;

        p {
            margin-top: 0;
        }
    `}
`;

export const Heading = styled.h2<{ $isTabletHalfWidthCard: boolean }>`
    ${({ $isTabletHalfWidthCard }) => css`
        margin-top: 0;

        ${$isTabletHalfWidthCard &&
        css`
            margin-bottom: 1rem;
        `}
    `}
`;

export const HeadingContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @media ${theme.breakpoints.large.media} {
            flex-direction: row;
            align-items: flex-start;
        }
    `}
`;

export const Icon = styled.svg`
    ${({ theme }) => css`
        height: 2rem;
        width: 2rem;

        @media ${theme.breakpoints.mediumSmall.media} {
            margin: 0.25rem 1rem 0 0;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0.75rem 1rem 0 0;
        }
    `}
`;

export const SocialWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;

    a {
        z-index: 1;
    }
`;

export const Wave = styled(WaveSVG)<{ $isTabletHalfWidthCard: boolean }>`
    ${({ theme, $isTabletHalfWidthCard }) => css`
        position: absolute;
        width: 72.5rem;
        left: -31.5rem;
        bottom: -8.5rem;
        pointer-events: none;

        @media ${theme.breakpoints.mediumSmall.media} {
            left: -20rem;
        }

        @media ${theme.breakpoints.medium.media} {
            width: 100%;
            left: 0;
            bottom: -5rem;

            ${$isTabletHalfWidthCard &&
            css`
                width: 72.5rem;
                left: -31.5rem;
                bottom: -8.5rem;
            `}
        }

        @media ${theme.breakpoints.large.media} {
            bottom: -10rem;
        }
    `}
`;
