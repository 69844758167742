import styled, { css } from 'styled-components';
import { Search } from '@solent-university/solent-icons';

import type { HeightType } from './Search';

export const Container = styled.div`
    position: relative;
    display: flex;
    max-width: 40rem;
`;

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const Input = styled.input<{ $heightType?: HeightType }>`
    ${({ theme, $heightType }) => css`
        width: 100%;
        height: 2.5rem;
        padding: 0 1.5rem;
        border: ${theme.commonStyles.brandPixel} solid var(--bg-color, ${theme.colors.primary.hex});

        border-radius: 0.5rem;
        line-height: normal;
        -webkit-appearance: none;

        @media ${theme.breakpoints.large.media} {
            height: 3.5rem;
        }

        &:focus {
            box-shadow: 0 0 0 2pt var(--bg-color, ${theme.colors.interactive.hex});
            outline: 0;
        }

        ${$heightType === 'tall' &&
        css`
            height: 3.5rem;

            @media ${theme.breakpoints.medium.media} {
                height: 4.25rem;
            }

            @media ${theme.breakpoints.large.media} {
                height: 4.6rem;
            }
        `}
    `}
`;

export const Button = styled.a<{ $heightType?: HeightType }>`
    ${({ theme, $heightType }) => css`
        display: grid;
        place-items: center;
        margin-left: 0.5rem;
        width: 3.5rem;
        min-width: 2.5rem;
        background-color: var(--bg-color, ${theme.colors.interactive.hex});
        color: var(--color, ${theme.colors.interactiveContrast.hex});
        border: none;
        border-radius: 0.5rem;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }

        @media ${theme.breakpoints.large.media} {
            min-width: 3.5rem;
        }

        ${$heightType === 'tall' &&
        css`
            width: 3.5rem;
            min-width: 3.5rem;

            @media ${theme.breakpoints.medium.media} {
                width: 4.25rem;
                min-width: 4.25rem;
            }

            @media ${theme.breakpoints.large.media} {
                width: 4.6rem;
                min-width: 4.6rem;
            }
        `}
    `}
`;

export const SearchIcon = styled(Search)`
    position: absolute;
    top: calc(50% - 0.5rem);
    right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    display: none;
`;

export const ButtonIcon = styled(Search)`
    width: 70%;
    height: 70%;
    max-width: 2.25rem;
    max-height: 2.25rem;
`;
