import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link';

export const Container = styled.div`
    ${({ theme }) => css`
        border-radius: 0.75rem;
        display: flex;
        background: ${theme.modules.siteNavigation.background};
        color: ${theme.modules.siteNavigation.primaryNavigation.color};
        overflow: hidden;
    `}
`;

export const Item = styled.li`
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`;

export const Link = styled(BaseLink)`
    && {
        color: inherit;
        line-height: 1.2;
        text-decoration: none;
        font-weight: inherit;
        font-size: inherit;
        position: relative;
        display: inline-block;

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 0.05em;
        }
    }
`;

export const PrimaryNavigation = styled.nav`
    ${({ theme }) => css`
        background: ${theme.modules.siteNavigation.primaryNavigation.background};
        padding: 2.25rem;
        width: 35%;
        flex: 0 0 auto;

        ${Item} {
            height: 2rem;
        }

        @media ${theme.breakpoints.huge.media} {
            width: 30%;
        }
    `}
`;

export const PrimaryList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.25rem;
`;

export const Icon = styled.svg<{ $iconColor: string }>`
    ${({ $iconColor }) => css`
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        position: relative;
        transform: translateY(25%);
        color: ${$iconColor};
        stroke-width: 2;
    `}
`;

export const SecondaryNavigationContainer = styled.div`
    ${({ theme }) => css`
        padding: 2.5rem 3rem 8.5rem 2.25rem;
        color: ${theme.modules.siteNavigation.secondaryNavigation.color};
        position: relative;
        width: 100%;
    `}
`;

export const SecondaryNavigation = styled.nav`
    font-weight: 300;
    width: 100%;

    ${Link} {
        margin-right: 4.75rem;
    }
`;

export const SecondaryHeading = styled.p`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1rem;
    margin: 0 0 1.5rem;
`;

export const SecondaryList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75rem;

    @media ${({ theme }) => theme.breakpoints.huge.media} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const Tag = styled.span<{ $type: 'trending' | 'updated' }>`
    ${({ theme, $type }) => css`
        position: absolute;
        top: 50%;
        left: calc(100% + 0.5rem);
        transform: translateY(-50%);
        border-radius: 0.375rem;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.25rem 0.5rem;
        line-height: 1;
        white-space: nowrap;
        text-transform: capitalize;
        background-color: ${$type === 'trending'
            ? theme.colors.accents[3].hex
            : theme.colors.accents[4].hex};
    `}
`;

export const PromotionWrapper = styled.div<{ $fullHeight: boolean }>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ $fullHeight }) => ($fullHeight ? '100%' : '6rem')};
`;
