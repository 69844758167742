export const commaSeparatedString = (
    input: string[],
    useOxfordComma = false
): string | undefined => {
    if (input.length < 1) {
        return undefined;
    }

    if (input.length < 2) {
        return input.join('');
    }

    const last = input.pop();
    const lastSeparator = useOxfordComma ? ', and ' : ' and ';
    return input.join(', ') + lastSeparator + last;
};

export const sanitizePhoneNumber = (number: string): string => {
    return number.replace(/[^+\d]+/g, '');
};

export const camelCaseToSentenceCase = (camelCaseText: string): string => {
    const resultArray = camelCaseText.replace(/([A-Z])/g, ' $1').split(' ');
    return resultArray
        .map((word, index) =>
            index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()
        )
        .join(' ');
};

export const parseFilterQuery = (query: string | string[] | undefined): string[] => {
    // If string, split commas
    if (typeof query === 'string') {
        return query.split(',').flat();
    }
    return [];
};
