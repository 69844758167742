import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import Image, { StaticImageData } from 'next/image';

import useBreakpoint from 'Common/hooks/useBreakpoint';
import useSticky from 'Common/hooks/useSticky';

import type { LinkProps } from 'Elements/Link';
import Button from 'Elements/Link/Arrow';

import SiteSearch from 'Components/Search/Site';

import SiteNavigation from 'Modules/SiteNavigation';
import HamburgerSiteNavigation from 'Modules/SiteNavigation/Hamburger';

import type { Props as ButtonProps } from './subcomponents/Button';
import type { Props as SiteNavigationProps } from 'Modules/SiteNavigation';

import {
    Container,
    LogoWrapper,
    LogoLink,
    SearchWrapper,
    EndContainer,
    ButtonWrapper,
    HamburgerContainer,
    XLargeContainer,
    SmallContainer,
    UtilityContainer,
    Inner,
    XLWrapper
} from './TopBar.styled';

interface Props {
    logo: {
        image: {
            default: {
                src: StaticImageData | string;
                alt: string;
            };
            sticky: {
                src: StaticImageData | string;
                alt: string;
            };
        };
        link: LinkProps;
    };
    button: ButtonProps;
    shortLabel: string;
    siteNavigation: SiteNavigationProps;
}

const TopBar: React.FC<Props> = props => {
    const { logo, button, shortLabel, siteNavigation } = props;
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { breakpoints } = useTheme();
    const isXLargeBreakpoint = useBreakpoint(breakpoints.xlarge.value);
    const isMinHeight = useBreakpoint(320, { type: 'height' });
    const { ref, isSticky, Sticky, stickyProps } = useSticky<HTMLDivElement>({
        disabled: !isMinHeight
    });

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    // Don't render until window is available, otherwise the search shows the mobile on first load on desktop devices.
    const searchComponent = isLoaded ? (
        <SearchWrapper $isVisible={!isSticky}>
            <SiteSearch
                placeholder="Search"
                label="Search"
                search={{
                    href: '/search?searchTerm={{search}}',
                    ariaLabel: 'search'
                }}
                name="Site search"
                isCollapsible={isSticky || !isXLargeBreakpoint}
                handleSearchOpen={(isOpen: boolean) => setIsSearchOpen(isOpen)}
            />
        </SearchWrapper>
    ) : null;

    const logoComponent = (
        <LogoWrapper $isSticky={isSticky} $isSearchOpen={isSearchOpen}>
            <LogoLink {...logo.link} prefetch={false}>
                {isSticky ? (
                    <Image {...logo.image.sticky} fill style={{ objectFit: 'contain' }} priority />
                ) : (
                    <Image {...logo.image.default} fill style={{ objectFit: 'contain' }} priority />
                )}
            </LogoLink>
        </LogoWrapper>
    );

    return (
        <Sticky {...stickyProps}>
            {/* large device */}
            <div ref={ref}>
                <XLWrapper $isSticky={isSticky}>
                    <XLargeContainer>
                        <Container $isSticky={isSticky} className="m-topbar">
                            <Inner>
                                {logoComponent}
                                <SiteNavigation {...siteNavigation} isSticky={isSticky} />
                                <EndContainer>
                                    {searchComponent}
                                    {isSticky && (
                                        <ButtonWrapper>
                                            <Button {...button} />
                                        </ButtonWrapper>
                                    )}
                                </EndContainer>
                            </Inner>
                        </Container>
                    </XLargeContainer>
                </XLWrapper>
                {/* Small device */}
                <SmallContainer>
                    <Inner>
                        <Container $isSticky={isSticky} className="m-topbar">
                            <UtilityContainer>
                                <HamburgerContainer>
                                    <HamburgerSiteNavigation {...siteNavigation} />
                                </HamburgerContainer>
                                {searchComponent}
                            </UtilityContainer>
                            {logoComponent}
                            {isSticky && (
                                <ButtonWrapper>
                                    <Button {...button} label={shortLabel} />
                                </ButtonWrapper>
                            )}
                        </Container>
                    </Inner>
                </SmallContainer>
            </div>
        </Sticky>
    );
};

export default TopBar;
